<template>
  <section v-if="show">
    <b-alert
      variant="danger"
      show
    >
      <h4 class="alert-heading">
        <feather-icon
          icon="AlertCircleIcon"
          size="12"
        /> &nbsp; Aviso importante
      </h4>
      <div class="alert-body">
        <span>
          Nossas maquinas não são renováveis! Ao final da data de expiração é necessário comprar novamente ou caso não tenha em estoque, basta solicitar uma nova maquina com uns dias de antecedência.
        </span>

        <br> <br>

        <b-button
          size="sm"
          variant="relief-primary"
          @click.stop="openVPSRenewLink()"
        >
          Clique aqui para solicitar
        </b-button>
      </div>
    </b-alert>
    <div class="text-center">
      <h1 class="mt-5">
        Preço da VPS e VPN
      </h1>
      <p class="mb-2 pb-75">
        As melhores vps e vpn disponíveis para você utilizar em suas contas :)
      </p>
    </div>

    <b-row class="pricing-card">
      <b-col
        offset-sm-2
        sm="10"
        md="12"
        offset-lg="2"
        lg="10"
        class="mx-auto"
      >
        <b-row>
          <b-col md="4">
            <b-card
              :class="pricing.standardPlan.popular ? 'popular' : ''"
              align="center"
            >
              <div
                v-show="pricing.standardPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <img
                :src="pricing.standardPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              >
              <!--/ img -->
              <h3>{{ pricing.standardPlan.title }}</h3>
              <b-card-text>
                {{ pricing.standardPlan.description }}
              </b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                  >R$</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >{{ pricing.standardPlan.price }}</span>
                  <sub
                    class="
                      pricing-duration
                      text-body
                      font-medium-1 font-weight-bold
                    "
                  >/semanal</sub>
                </div>
              </div>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(item, index) in pricing.standardPlan.benefits"
                  :key="index"
                >
                  {{ item }}
                </b-list-group-item>
              </b-list-group>

              <b-button
                block
                variant="relief-primary"
                class="mt-1"
                :disabled="loading"
                @click.stop="purchaseDialog(pricing.standardPlan)"
              >
                <span v-if="!loading">Comprar</span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>

              <b-badge
                class="mt-1"
                :variant="pricing.standardPlan.stock > 0 ? 'primary' : 'danger'"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-25"
                />
                <span>Estoque: {{ pricing.standardPlan.stock }}</span>
              </b-badge>
            </b-card>
          </b-col>

          <b-col md="4">
            <b-card
              :class="pricing.basicPlan.popular ? 'popular' : ''"
              align="center"
            >
              <div
                v-show="pricing.basicPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <img
                :src="pricing.basicPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              >
              <!--/ img -->
              <h3>{{ pricing.basicPlan.title }}</h3>
              <b-card-text> {{ pricing.basicPlan.description }} </b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                  >R$</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >{{ pricing.basicPlan.price }}</span>
                  <sub
                    class="
                      pricing-duration
                      text-body
                      font-medium-1 font-weight-bold
                    "
                  >/quinzenal</sub>
                </div>
              </div>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(item, index) in pricing.basicPlan.benefits"
                  :key="index"
                >
                  {{ item }}
                </b-list-group-item>
              </b-list-group>

              <b-button
                block
                variant="relief-primary"
                class="mt-1"
                :disabled="loading"
                @click.stop="purchaseDialog(pricing.basicPlan)"
              >
                <span v-if="!loading">Comprar</span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>

              <b-badge
                class="mt-1"
                :variant="pricing.basicPlan.stock > 0 ? 'primary' : 'danger'"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-25"
                />
                <span>Estoque: {{ pricing.basicPlan.stock }}</span>
              </b-badge>
            </b-card>
          </b-col>

          <b-col md="4">
            <b-card
              :class="pricing.advancedPlan.popular ? 'popular' : ''"
              align="center"
            >
              <div
                v-show="pricing.advancedPlan.popular"
                class="pricing-badge text-right"
              >
                <b-badge
                  variant="light-primary"
                  pill
                >
                  Popular
                </b-badge>
              </div>
              <!-- img -->
              <img
                :src="pricing.advancedPlan.img"
                class="mb-2 mt-5"
                alt="basic svg img"
              >
              <!--/ img -->
              <h3>{{ pricing.advancedPlan.title }}</h3>
              <b-card-text>
                {{ pricing.advancedPlan.description }}
              </b-card-text>

              <div class="annual-plan">
                <div class="plan-price mt-2">
                  <sup
                    class="font-medium-1 font-weight-bold text-primary"
                  >R$</sup>
                  <span
                    class="pricing-basic-value font-weight-bolder text-primary"
                  >{{ pricing.advancedPlan.price }}</span>
                  <sub
                    class="
                      pricing-duration
                      text-body
                      font-medium-1 font-weight-bold
                    "
                  >/quinzenal</sub>
                </div>
              </div>

              <b-list-group class="list-group-circle text-left">
                <b-list-group-item
                  v-for="(item, index) in pricing.advancedPlan.benefits"
                  :key="index"
                >
                  {{ item }}
                </b-list-group-item>
              </b-list-group>

              <b-button
                block
                variant="relief-primary"
                class="mt-1"
                :disabled="loading"
                @click.stop="purchaseDialog(pricing.advancedPlan)"
              >
                <span v-if="!loading">Comprar</span>
                <b-spinner
                  v-else
                  small
                />
              </b-button>

              <b-badge
                class="mt-1"
                :variant="pricing.advancedPlan.stock > 0 ? 'primary' : 'danger'"
              >
                <feather-icon
                  icon="ArchiveIcon"
                  class="mr-25"
                />
                <span>Estoque: {{ pricing.advancedPlan.stock }}</span>
              </b-badge>
            </b-card>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import {
  BAlert,
  BCard,
  BCardText,
  BRow,
  BCol,
  BListGroup,
  BListGroupItem,
  BBadge,
  BButton,
  BSpinner
} from 'bootstrap-vue'
import SwalMessages from '../BuyServices/utils/SwalMessages'

export default {
  components: {
    BAlert,
    BCard,
    BCardText,
    BRow,
    BCol,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    BSpinner
  },
  data: () => ({
    show: false,
    loading: false,
    pricing: {}
  }),
  async mounted () {
    await this.getVPS()
  },
  methods: {
    openVPSWithEmulatorLink () {
      window.open(
        'https://api.whatsapp.com/send?phone=5521969315819&text=Ol%C3%A1,%20tenho%20interesse%20em%20uma%20VPS%20com%20emulador!',
        '_blank'
      )
    },
    openVPSRenewLink () {
      window.open(
        'https://api.whatsapp.com/send?phone=5521969315819&text=Oi,%20tenho%20o%20interesse%20em%20renovar%20a%20minha%20VPS.',
        '_blank'
      )
    },
    async getVPS () {
      const url = '/user/services/vps'

      await this.$http
        .get(url)
        .then(res => {
          this.vps = res.data

          const basicPlan = res.data.filter(item => item.type === 1)[0]
          const standardPlan = res.data.filter(item => item.type === 2)[0]
          const advancedPlan = res.data.filter(item => item.type === 3)[0]

          basicPlan.benefits = JSON.parse(basicPlan.benefits)
          standardPlan.benefits = JSON.parse(standardPlan.benefits)
          advancedPlan.benefits = JSON.parse(advancedPlan.benefits)

          this.pricing.basicPlan = {
            ...basicPlan,
            img: require('@/assets/images/illustration/Pot1.svg')
          }

          this.pricing.standardPlan = {
            ...standardPlan,
            img: require('@/assets/images/illustration/Pot2.svg')
          }

          this.pricing.advancedPlan = {
            ...advancedPlan,
            img: require('@/assets/images/illustration/Pot3.svg')
          }
        })
        .finally(() => {
          this.show = true
        })
    },
    purchaseDialog (plan) {
      this.$swal({
        title: `Você tem certeza que deseja comprar a VPS do Plano ${plan.title}?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim, comprar!',
        cancelButtonText: 'Fechar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
        // eslint-disable-next-line no-unused-vars
      }).then(result => {
        if (result.value) {
          this.purchase(plan)
        }
      })
    },
    async purchase (plan) {
      this.loading = true
      const url = `/user/services/vps/purchase/${plan.type}`

      await this.$http.get(url).then(res => {
        if (res.data.message === 'successfully created.' && res.status === 201) this.success()
        if (res.data.message === 'no balance.' && res.status === 200) this.noBalance()
        if (res.data.message === 'no stock available.' && res.status === 200) this.noStock()
      }).finally(() => {
        this.loading = false
      })
    },
    success () {
      this.$swal(SwalMessages.vps_success).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Minhas VPS' })
        : null))
    },
    noStock () {
      this.$swal({
        title: 'Sem estoque disponível para a VPS selecionada.',
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary'
        },
        buttonsStyling: false
      })
    },
    noBalance () {
      this.$swal(SwalMessages.no_balance).then(buttons => (buttons.isConfirmed
        ? this.$router.push({ name: 'Adicionar Saldo' })
        : null))
    }
  }
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-pricing.scss";
@import "@core/scss/vue/libs/vue-sweetalert.scss";
</style>
